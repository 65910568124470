<script setup lang="ts">
    import type { Asset, AssetStyleSize } from '~/@types/cms';

    interface VideoProps {
        data: Asset;
        description?: string;
        styleSize?: AssetStyleSize | 'none'; // none used in Gallery
        previewImage?: Asset;
        notRounded?: boolean;
    }

    const props = defineProps<VideoProps>();

    const nuxtApp = useNuxtApp();

    const code = computed(() => {
        if (typeof props.data === 'object' && props.data?.id?.startsWith('yt:')) {
            return props.data.data.code;
        }
        return null;
    });

    const isFullBleed = computed(() => props.styleSize === 'fullbleed');

    const loadVideo = () => {
        const c = unref(code);
        if (c) {
            nuxtApp.$eventEmitter.emit('open-video', {
                code: c,
            });
        }
    };
</script>

<template>
    <div
        v-if="code"
        :class="[
            'atm-video flex h-full flex-col overflow-hidden',
            {
                'mx-auto max-w-[790px]': !styleSize,
                'relative lg:max-h-screen-desktop lg:min-h-screen-desktop': isFullBleed,
                'rounded-2xl': !notRounded && !isFullBleed,
            },
        ]">
        <button
            type="button"
            :class="['h-full cursor-pointer', isFullBleed ? 'w-full' : 'relative lg:mx-auto']"
            @click="loadVideo">
            <atm-video-preview
                :code="code"
                :preview-image="previewImage"
                :fullbleed="isFullBleed" />
        </button>
        <div
            v-if="description"
            class="mt-4 text-center">
            <renderer-html :content="woomTextFormat(description)" />
        </div>
    </div>
</template>
